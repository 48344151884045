<template> 
<v-app>
	<v-main>
		<v-container fluid>
			<v-row align="center" justify="center">
					<div style="height:100vh;" class="d-flex align-center">
						<v-card elevation="0">
							<v-card-title >
								<form class="d-flex-column">
									<v-img 
										class="pa-1"
										contain 
										src="@/assets/img/Logo-506.png">
									</v-img>
									<div class="d-flex align-center justify-center">
										<h5 class="primary--text">Reservations506 WebApp</h5>
									</div>
									
									<div class="d-flex-column align-center justify-center pa-3">
										<v-text-field
											label="Usuario"
											prepend-icon="person"
											v-model="form.username"
											:disabled="loading"
											:loading="loading"
										></v-text-field>
										<v-text-field
											type="password"
											label="Contraseña"
											prepend-icon="lock"
											v-model="form.password"
											:disabled="loading"
											:loading="loading"
											@keyup.enter="submit"
										></v-text-field>
										<div class="d-flex align-center justify-center">
											<a href="#" class="text-body-2 primary--text">Forgot Password?</a>
										</div>
									</div>
									<div class="text-center">
										<v-btn
											width="240"
											rounded
											color="primary"
											dark
											v-bind:style="loading ? color : 0"
											:disabled="loading"
											:loading="loading"
											@click="submit"
										>
											Login
										</v-btn>
									</div>
								</form>
						</v-card-title>
						</v-card>
					</div>
			</v-row>
		</v-container>
	</v-main>
</v-app>
</template>

<script>
import {axiosN506, axiosB} from '../api/axiosBd'
export default {
	data() {
		return {
			form: {
				username: "",
				password: "",
			},
			loading: false,
			color: 'blue',
			
		}
	},
	methods: {
		submit() {
			this.loading = true
      const credentials = {
        username: this.form.username,
        password: this.form.password,
      }
      axiosN506
        .post(`auth/login`, credentials)
        .then((res) => {
          this.$store.commit("setAccessToken", res.data.token)
          this.$store.commit("setRefreshToken", res.data.refreshToken)
					this.$router.push({ name: "Home" })
					this.loading = false
					this.$message({
            showClose: true,
            message: "Bienvenido a Reservations506 WebApp.",
            type: "success",
          })
          
        })
        .catch((err) => {
					this.loading = false
					if (err == "Error: Network Error") {
						this.$message({
							showClose: true,
							message: `Oops, Error de conexion a internert, favor revisar su conexion a internet.`,
							type: "error",
						});
					} else if (err == "Error: timeout of 1000ms exceeded") {
						this.$message({
							showClose: true,
							message: `Oops, ${err + " Revisar Conexion DB"}`,
							type: "error",
						});
					} else {
						this.$message({
							showClose: true,
							message: `Oops, ${err.response.data.message}`,
							type: "error",
						});
					}
				})

			axiosB
			.post(`api/token/`, credentials)
			.then((res) => {
				console.log(res)
				this.$store.commit("setAccessTokenPy", res.data.access)
				this.$store.commit("setRefreshTokenPy", res.data.refresh)
			})
			.catch((err) => {
				console.log(err)
				if (err == "Error: Network Error en Servicios Py") {
					this.$message({
						showClose: true,
						message: `Oops, Error de conexion a internert Servicios Py, favor revisar su conexion a internet.`,
						type: "error",
					});
				} else if (err == "Error: timeout of 1000ms exceeded Servicios P") {
					this.$message({
						showClose: true,
						message: `Oops, ${err + " Revisar Conexion DB Servicios P"}`,
						type: "error",
					});
				} else {
					this.$message({
						showClose: true,
						message: `Oops, ${err.response} Servicios Py`,
						type: "error",
					});
				}
			})

    },
	},
};
</script>

<style>

* {
	box-sizing: border-box;
}







</style>